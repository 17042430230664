import React from 'react';
import {isValidMotionProp, motion} from 'framer-motion';

import {
  Box,
  Text,
  HStack,
  VStack,
  Input,
  chakra
} from '@chakra-ui/react';

const Cursor = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children'
})

const _BufferLine = ({text, ...props}) => (
  <Text 
    w='full' 
    padding={0} 
    margin={0} 
    className="terminal-buffer" 
    lineHeight={1}
    {...props}
  >
    {text}
</Text>
);

const BufferLine = React.memo(_BufferLine);

const Component = () => {
  const [buffer, setBuffer] = React.useState([]);
  const [command, setCommand] = React.useState('');

  const promptRef = React.useRef(null);

  const typeItOut = (text: string | string[]) => {
    const content = typeof text == 'string' ? [text] : text;
    setBuffer(buffer.concat(content));
  }

  return (
    <VStack w='full' overflowX='hidden' overflowY='auto' spacing={0}>
      {
        buffer.map((line, index) => <BufferLine key={`buffer_line_${index}`} text={line}/>)
      }
      
      {buffer.length > 0 && <Box h={1}/>}

      <HStack w='full' align='flex-start' spacing={0} padding={0} margin={0}>
        <Text className="terminal-buffer" padding={0} margin={0} pr={2}>
          [wess.io] $
        </Text>

        {
          command.length > 0 && (
            <Text className="terminal-buffer" padding={0} margin={0}>
              {command}
            </Text>
          )
        }

        <Cursor 
          className="terminal-buffer" 
          animate={{opacity: 0}}
          transition={{duration: 0.6, repeat: Infinity}}
          ref={promptRef}
        >
          _
        </Cursor>

        <form onSubmit={(e) => {
          e.preventDefault();

          let cmd = command;

          if(command.length > 0) {
            
            switch(command) {
              case 'clear':
                setBuffer([]);
                break;
              case 'github':
                window.open('https://github.com/wess', '_blank');
                break;
              case 'twitter':
                window.open('https://twitter.com/wesscope', '_blank');
                break;
              case 'instagram':
                window.open('https://www.instagram.com/wesscope/', '_blank');
                break;
              case 'linkedin':
                window.open('https://www.linkedin.com/in/wesleycope/', '_blank');
                break;
              case 'email':
                window.open('mailto:site@wess.io', '_blank');
                break;
              default:
                cmd = 'Unknown command.';
                break;
            }

            setBuffer([...buffer, cmd]);
            setCommand('');
          }
        }}>
        <Input
        position='absolute'
        left={-100000}
        autoFocus={true}
        opacity={0}
          value={command}
          onChange={(e) => {
            if(command.length < 60) {
              setCommand(e.target.value)
            }
          }}
        />
        </form>
      </HStack>
    </VStack>
  );
};

export default Component;