import React from 'react';

import {
  HStack,
  Box,
  Spacer,
  Button,
  Link,
  Heading,
  Text,
  ButtonGroup,
  IconButton,
  Image
} from '@chakra-ui/react';

import {
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoGithub,
  IoMail,
  IoLogoInstagram
} from 'react-icons/io5'

import PixelFace from '../assets/pixel-face.png';

const textColor = "blackAlpha.800";
const iconSize = "24px";

const Component = (_props) => (
  <HStack w='full' align='flex-start' bg='#fff' px={10} pt={2}>
    <Box h='full'>
      <Image h='38px' src={PixelFace} />
    </Box>

    <Heading className="branding" textColor={textColor}>Wess Cope</Heading>

    <Spacer/>

    <ButtonGroup>
      <IconButton variant='ghost' aria-label='github' icon={<IoLogoGithub color={textColor} size={iconSize}/>} onClick={() => window.open('https://github.com/wess', '_blank')}/>
      <IconButton variant='ghost' aria-label='twitter' icon={<IoLogoTwitter color={textColor} size={iconSize}/>} onClick={() => window.open('https://twitter.com/wesscope', '_blank')}/>
      <IconButton variant='ghost' aria-label='instagram' icon={<IoLogoInstagram color={textColor} size={iconSize}/>} onClick={() => window.open('https://www.instagram.com/wesscope/', '_blank')}/>
      <IconButton variant='ghost' aria-label='linkedin' icon={<IoLogoLinkedin color={textColor} size={iconSize}/>} onClick={() => window.open('https://www.linkedin.com/in/wesleycope/', '_blank')}/>
      <IconButton variant='ghost' aria-label='email' icon={<IoMail color={textColor} size={iconSize}/>} onClick={() => window.open('mailto:site@wess.io', '_blank')}/>
    </ButtonGroup>
  </HStack>
);

export default Component;
