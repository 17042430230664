import React from 'react';

import {
  HStack,
  Box,
  Spacer,
  Button,
  Link,
  Heading,
  Text,
  ButtonGroup,
  IconButton
} from '@chakra-ui/react';

import {
  CPlainIcon,
  CplusplusPlainIcon,
  ElixirPlainIcon,
  JavascriptPlainIcon,
  TypescriptPlainIcon,
  ReactOriginalIcon,
  LuaPlainIcon,
  PythonPlainIcon,
  RustPlainIcon,
  SwiftPlainIcon,
  BashPlainIcon,
  PhpPlainIcon,
  RubyPlainIcon,
  RailsPlainIcon,
  DartPlainIcon,
  FlutterPlainIcon,
  PostgresqlPlainIcon,
  DockerPlainIcon,
} from 'react-devicons';

const techSize = '32px';
const techColor = '#474747';

const techs = [
  <CPlainIcon size={techSize} color={techColor}/>,
  <CplusplusPlainIcon size={techSize} color={techColor}/>,
  <ElixirPlainIcon size={techSize} color={techColor}/>,
  <JavascriptPlainIcon size={techSize} color={techColor}/>,
  <TypescriptPlainIcon size={techSize} color={techColor}/>,
  <ReactOriginalIcon size={techSize} color={techColor}/>,
  <LuaPlainIcon size={techSize} color={techColor}/>,
  <PythonPlainIcon size={techSize} color={techColor}/>,
  <RustPlainIcon size={techSize} color={techColor}/>,
  <SwiftPlainIcon size={techSize} color={techColor}/>,
  <BashPlainIcon size={techSize} color={techColor}/>,
  <PhpPlainIcon size={techSize} color={techColor}/>,
  <RubyPlainIcon size={techSize} color={techColor}/>,
  <RailsPlainIcon size={techSize} color={techColor}/>,
  <DartPlainIcon size={techSize} color={techColor}/>,
  <FlutterPlainIcon size={techSize} color={techColor}/>,
  <PostgresqlPlainIcon size={techSize} color={techColor}/>,
  <DockerPlainIcon size={techSize} color={techColor}/>,
];

const Component = (_props) => (
  <Box w='full' overflowX='auto' pt={1} pb={3}>
    <HStack bg='#fff' spacing='34px' align='center'>
      <Box h='full' flex={1}>&nbsp;</Box>
      {
        techs.map((t, index) => (
          <Box key={`t_${index}`}> 
            {t}
          </Box>
        ))    
      }
      <Box h='full' flex={1}>&nbsp;</Box>
    </HStack>
  </Box>
);

export default Component;