import React from 'react';

const Component = () => (
  <pre style={{color: '#adffa2', fontSize: '12px'}}>
██╗    ██╗███████╗███████╗███████╗     ██████╗ ██████╗ ██████╗ ███████╗ <br/>
██║    ██║██╔════╝██╔════╝██╔════╝    ██╔════╝██╔═══██╗██╔══██╗██╔════╝ <br/>
██║ █╗ ██║█████╗  ███████╗███████╗    ██║     ██║   ██║██████╔╝█████╗   <br/>
██║███╗██║██╔══╝  ╚════██║╚════██║    ██║     ██║   ██║██╔═══╝ ██╔══╝   <br/>
╚███╔███╔╝███████╗███████║███████║    ╚██████╗╚██████╔╝██║     ███████╗ <br/>
&nbsp;╚══╝╚══╝ ╚══════╝╚══════╝╚══════╝     ╚═════╝ ╚═════╝ ╚═╝     ╚══════╝ <br/>
 <br/>
 ╔════════════════════════════════════════════════════════════════════╗ <br/>
 ║ Welcome, the following commands are available:                     ║ <br/>
 ║ ------------------------------------------------------------------ ║ <br/>
 ║   <strong>github</strong>:    Head over to my Github                                ║ <br/>
 ║   <strong>twitter</strong>:   Follow me on Twitter.                                 ║ <br/>
 ║   <strong>instagram</strong>: Take a peek into my world                             ║ <br/>
 ║   <strong>linkedin</strong>:  Business, business, business.                         ║ <br/>
 ║   <strong>email</strong>:     Send me an email.                                     ║ <br/>
 ╚════════════════════════════════════════════════════════════════════╝ <br/>
  </pre>
);

export default Component;