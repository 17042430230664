import React from 'react';

import {
  VStack,
  HStack,
  Box,
  Text,
  Spacer,
  Container,
} from '@chakra-ui/react';

import Banner from './banner';
import Buffer from './buffer';

const Component = () => {
  return (
    <VStack h='full' minHeight='400px' w='600px' align='center' flex={1} bg='#1C211C' p={10} borderRadius={14}>
      <Banner/>
      <Buffer/>
    </VStack>
  );
}

export default Component;