export default {
  polar: [
    "#2E3440",
    "#3B4252",
    "#434C5E",
    "#4C566A",
  ],
  snow: [
    "#D8DEE9",
    "#E5E9F0",
    "#ECEFF4",      
  ],
  frost: [
    "#8FBCB",
    "#88C0D0",
    "#81A1C1",
    "#5E81AC",
  ],
  aurora: [
    "#BF616A",
    "#D08770",
    "#EBCB8B",
    "#A3BE8C",
    "#B48EAD",
  ]
};