import React from 'react';
import {createRoot} from 'react-dom/client';

import {
  ChakraProvider,
  ColorModeScript,
  Heading,
  Spacer,  
  VStack,
  HStack,
  Box,
  Text,
  Container,
  Image,
  Center
} from '@chakra-ui/react';

import {Theme} from '~/theme';

import {
  Global,
  css,
} from '@emotion/react';

import {
  NavBar,
  BottomBar,
  Terminal
} from './components';

import EightiesBG from './assets/80sbg.png';

export const cssFixes = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
   }
`;

const leftBGColor = '#31eeeb';
const rightBGColor = '#f0cc2b';

createRoot(document.getElementsByTagName('main')[0]).render(
  <>
    <ColorModeScript initialColorMode={Theme.config.initialColorMode}/>
    <ChakraProvider theme={Theme}>
      <Global styles={cssFixes}/>
      <VStack w='full' h='100vh' minHeight='800px'>
        <NavBar/>

        <Box w='full' flex={1} bg='#000' borderY="1px solid #000" overflow='auto' bgImage={EightiesBG} bgSize='cover'>
          <HStack w='full' h='full' align='center' p={16}>
            <VStack h='full' align='center' flex={1}>
              <Spacer/>
              <Container bg="rgba(0, 0, 0, 0.8)" p={10} border="1px solid #000" borderRadius={4}>
                <Heading fontSize='4.2rem' className='retro' color='#F4CC06'>
                  Application Development Disorder.
                </Heading>
                
                <Box h='3rem'/>

                <Text color="#24BCD8" fontWeight='600'>
                  I'm Wess, a software engineer based in Columbia, SC (US).
                  I have been designing and developing software for 20+ years and
                  a love for open source software and community.
                </Text>
              </Container>
              <Spacer/>
            </VStack>

            <Terminal/>
          </HStack>
        </Box>

        <BottomBar/>
      </VStack>
    </ChakraProvider>
  </>
);

